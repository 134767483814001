<script>
export default {
  name: "downloadList",
  props: ["isAndroid", "androidDow", "iosDow", "isTip", "iosTfAddress"],
  methods: {
    appDow(iosIos = false) {
      if (this.isTip) {
        this.$emit("showTip");
        return;
      }

      if (iosIos) {
        if (!this.iosDow) {
          this.$message({
            message: "暂未开放",
            center: true,
            type: "warning",
            offset: 300,
            customClass: "myBox",
          });
          return;
        }
        location.href = this.iosDow;
      } else {
        if (!this.androidDow) {
          this.$message({
            message: "暂未开放",
            center: true,
            type: "warning",
            offset: 300,
          });
          return;
        }
        location.href = this.androidDow;
      }
    },
  },
};
</script>

<template>
  <div class="type">
    <div class="typeBut" @click="appDow(true)">
      <img src="../../assets/ios.png" class="icon-app" alt="" />
      <p>IOS测试</p>
    </div>
    <div class="typeBut android" @click="appDow(false)">
      <img src="../../assets/android.png" class="icon-app" alt="" />
      <p>Android</p>
    </div>
  </div>
</template>

<style lang="less" scoped>
.type {
  margin-top: 120px;
  display: flex;
  justify-content: space-evenly;

  .icon-app {
    width: 14px;
    height: 16px;
    margin-right: 8px;
  }
}

.typeBut {
  width: 108px;
  height: 44px;
  background-color: #313131;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-size: 14px;
  color: #ffffff;

  &.android {
    background-color: #a53ef8;
  }
}
</style>
