<template>
  <div id="wrap">
    <div class="head">
      <img src="../assets/ys_logo.png" class="logo" alt="" />
      <div class="title">音色专用</div>
    </div>

    <div class="tip" v-show="showT">
      <img src="../assets/img_h5_guide.png" alt="" />
    </div>
    <page
      @show="show"
      @showTip="showTip"
      :isTip="isTip"
      :isAndroid="isAndroid"
      :androidDow="androidDow"
      :iosDow="iosDow"
      :iosTfAddress="iosTfAddress"
    />
  </div>
</template>

<script>
import page from "./components/pageMain.vue";
import { isSafari } from "@/utils/isSafari";
import config from "@/api/config";

export default {
  components: {
    page,
  },
  data() {
    return {
      isAndroid: null,
      androidDow: "",
      iosDow: "",
      rechargeUrl: "",
      height: window.outerHeight,
      width: window.innerWidth,
      mySwiper: null,
      isShow: false,
      isTip: false,
      showT: false,
      iosTfAddress: "",
      isSafariIos: false,
    };
  },

  created() {
    let user = navigator.userAgent;
    this.isAndroid =
      user.indexOf("Android") > -1 || user.indexOf("Adr") > -1 ? true : false;
    this.isTip = user.toLowerCase().indexOf("micromessenger") > -1;
    !this.isAndroid && user.toLowerCase().indexOf("safari") < -1
      ? (this.isTip = true)
      : null;
    this.getData();
    this.isSafariIos = isSafari();
  },

  methods: {
    showTip() {
      this.showT = true;
    },

    show() {
      this.isShow = false;
    },

    getData() {
      this.$axios.post(config.domain + "/api/v1/common/config").then((res) => {
        this.androidDow = res.data.data.business_android_address;
        this.iosDow = res.data.data.business_ios_address;
        this.rechargeUrl = res.data.data.business_recharge_link;
        this.iosTfAddress = res.data.data.business_ios_tf_address;
      });
    },
  },
};
</script>

<style scoped lang="less">
#wrap {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.head {
  position: fixed;
  top: 0;
  z-index: 80;
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;

  background: linear-gradient(to bottom right, #a53ef8, #d8b0f8);

  .logo {
    width: 36px;
    height: 36px;
    margin-left: 16px;
    border-radius: 8px;
  }

  .title {
    font-size: 20px;
    color: #fff;
    margin-left: 14px;
  }
}

.tip {
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: flex-end;

  img {
    width: 321px;
    height: 239px;
  }
}

.showbutton {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.buttonsolid {
  width: 20px;
  height: 1.5px;
  border-radius: 16px;
  background-color: #313131;
  margin-bottom: 6px;
}

.buttonConten {
  position: absolute;
  bottom: -86px;
  left: -66px;
  width: 88px;
  height: 76px;
  background-image: url("@/assets/bgBut.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 20;
}

.buttonFont {
  color: #fff;
  font-size: 14px;
}

.buttonConSolid {
  height: 1px;
  width: 100%;
  background-color: #737373;
}
</style>
