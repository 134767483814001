<template>
  <div style="height: 100%" class="container" id="page" @touchmove="touchmove">
    <img class="c_logo" src="../../assets/ys_logo.png" alt="" />
    <download-list
      @showTip="showTip"
      :isTip="isTip"
      :isAndroid="isAndroid"
      :androidDow="androidDow"
      :iosDow="iosDow"
      :iosTfAddress="iosTfAddress"
    />
    <div class="link" @click="link">
      <img
        src="../../assets/pathText.png"
        style="width: 14px; height: 14px; margin-right: 5px"
        alt=""
      />
      “未受信任的企业级开发者”的解决办法
    </div>
  </div>
</template>

<script>
import DownloadList from "@/views/components/downloadList.vue";
import config from "@/api/config";

export default {
  components: { DownloadList },

  data() {
    return {
      height: window.innerHeight,
    };
  },

  props: ["isAndroid", "androidDow", "iosDow", "isTip", "iosTfAddress"],

  created() {},

  methods: {
    link() {
      location.href = config.domain + "/wei/";
    },

    touchmove() {
      this.$emit("show");
    },
    showTip() {
      this.$emit("showTip");
    },

    appDow(type) {
      if (this.isTip) {
        this.$emit("showTip");
        return;
      }

      if (type) {
        if (this.iosDow == "null" || this.iosDow == null) {
          this.$message({
            message: "暂未开放",
            center: true,
            type: "warning",
            offset: 300,
            customClass: "myBox",
          });
          return;
        }
        location.href = this.iosDow;
      } else {
        if (this.androidDow == "null") {
          this.$message({
            message: "暂未开放",
            center: true,
            type: "warning",
            offset: 300,
          });
          return;
        }
        location.href = this.androidDow;
      }
    },
  },
};
</script>

<style scoped lang="less">
.container {
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  padding-top: 120px;
  background: url("../../assets/bg_login.png") no-repeat;
  background-size: 100% 100%;

  .c_logo {
    display: block;
    width: 108px;
    height: 108px;
    margin: 0 auto;
    border-radius: 12px;
    border: 1px solid #d8b0f8;
  }
}

.head {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-left: 8px;
  width: 98.2px;
  height: 18.78px;
}

.button {
  width: 88px;
  height: 32px;
  border-radius: 20px;
  background: rgb(255, 133, 14);
  font-family: ".苹方-简";
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  margin-right: 16px;
}

.fontOne {
  font-family: ".苹方-简";
  font-size: 14px;
  color: #343537;
  text-align: center;
  margin-top: 33px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  color: #3b82f6;
  font-size: 14px;
}

.mobile {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  position: relative;
}

.mobileHome {
  width: 251px;
  height: 542px;
  position: absolute;
  border-radius: 32px;
  top: 13px;
}

.mobileTop {
  position: absolute;
  background-color: #000000;
  width: 74.76px;
  height: 21.2px;
  z-index: 80;
  top: 17px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}
</style>
